import './StatsRow.css'
import React from "react";
import {ProjectInfo} from '../../../../types/ProjectInfoTypes';
import StatsBlock from './StatsBlock';
import {useMediaQuery} from "react-responsive";
import { MediaQueries } from '../../../../constants/MediaQueries';

const StatsRow: React.FC<{stats: ProjectInfo['stats']}> = ({stats}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })

  return(
    <div className={'stats-row-wrapper-absolute'}>
      <div className={`stats-row-wrapper ${isMobile && 'mobile'}`} style={{ gridTemplateColumns: isMobile ? '1fr 1fr' : `repeat(${stats?.length}, 1fr`}}>
        {stats?.map(stat => <StatsBlock {...stat} />)}
      </div>
    </div>
  )
}

export default StatsRow
