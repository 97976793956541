const wordsToCheckEng = [
  'a', 'as', 'an', 'in', 'on', 'at', 'to', 'for', 'and', 'or', 'of', 'the', 'be', 'by', 'are'
];

const wordsToCheckRus = [
  "а", "в", "и", "с", "для", "или", "да", "ибо", "ли", "же", "то", "что", "как",
  "чем", "от", "до", "по", "из", "у", "на", "о", "об", "обо", "под", "за", "над", "к", "ко", "бы",
];

const wordsToCheck = new Set([...wordsToCheckRus, ...wordsToCheckEng])

export const formatText = (text: string): string => {
  const words = text.split(' ')
  let result = words[0]

  for (let i = 1; i < words.length; i++) {
    if (wordsToCheck.has(words[i - 1].toLowerCase())) {
      result += `\u00A0${words[i]}`
    } else {
      result += ` ${words[i]}`
    }
  }

  return result
}
