import './StatsBlock.css'
import React from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/MediaQueries";
import {useSelector} from "react-redux";
import {RootStoreState} from "../../../../redux/ReduxStore";
import {Lang} from "../../../../constants/Lang";
import {ColorTheme} from "../../../../constants/ColorTheme";
import TextFormatterComponent from '../../../../utils/formatters/TextFormatterComponent';

export interface StatsBlockProps {
  num: string;
  descriptionRUS: string;
  descriptionENG: string;
}

const StatsBlock: React.FC<StatsBlockProps> = ({ num, descriptionENG, descriptionRUS }) => {
  const isMobile = useMediaQuery({ query: MediaQueries.MOBILE })
  const currLang = useSelector((state: RootStoreState) => state.lang)
  const currTheme = useSelector((state: RootStoreState) => state.colorTheme)

  return(
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', maxWidth: isMobile ? '200px' : '300px' }}>
      <h3
        className={`
            animation-02s-all 
            ${isMobile ? 'text-desktop-stats' : 'text-mobile-stats'} 
            ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}
          `}
      >
        {num}
      </h3>
      <TextFormatterComponent
        text={currLang === Lang.ENG ? descriptionENG : descriptionRUS}
        classNames={
          `
            animation-02s-all 
            ${!isMobile ? 'desktop-accent-text' : 'mobile-main-text'}
            ${currTheme === ColorTheme.DARK ? 'text-primary-text-dark' : 'text-primary-text'}
          `
        }
      />
    </div>
  )
}

export default StatsBlock
